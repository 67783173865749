<template>
  <div class="text-left">
    <BaseHeader :title="'fines'"></BaseHeader>

    <BaseLoading
      style="min-height: 400px; height: 90vh"
      v-if="loading"
    ></BaseLoading>

    <section v-else class="pt-4">
      <div class="row" v-if="finesTotal && finesTotal > 0">
        <div class="col col-12 col-sm-6 col-md-3">
          <v-alert
            class="rounded"
            color="error"
            prominent
            dense
            text
            type="error"
          >
            <h2 class="font-weight-bold">$ {{ finesTotal || 0.0 }}</h2>
            <span class="text-capitalize"> Total </span>
          </v-alert>
        </div>
      </div>

      <div class="card mt-2 border-0 bg-white shadow">
        <div class="card-body text-right d-flex" style>
          <v-text-field
            v-model="$store.state.search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="my-auto"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn
            v-if="userType !== userTypes.writer"
            color="primary"
            class="my-auto ml-2"
            size="small"
            @click.stop="
              edit = false;
              finesForm.reset();
              $store.state.dialog = true;
            "
            >New</v-btn
          >
        </div>
        <!-- datatable -->
        <v-data-table
          :mobile-breakpoint="0"
          :headers="finesHeaders"
          :items="fines"
          :search="$store.state.search"
          :disable-pagination="true"
          :hide-default-footer="true"
        >
          <template v-slot:[`item.amount`]="{ item }">
            ${{ item.amount || 0.0 }}
          </template>
          <template v-slot:[`item.order_id`]="{ item }">
            <router-link
              v-if="item.order_id != '-1'"
              :to="{ name: 'Order', params: { id: item.order_id } }"
              >{{ item.order_id || "" }}</router-link
            >
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              color="error"
              class="white--text"
              icon
              @click="
                editMode(item);
                edit = true;
                $store.state.dialog = true;
              "
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <!-- /datatable -->

        <!-- pagination -->
        <div class="card-footer" v-if="fines.length > 0">
          <p class="text-left">
            From {{ finesFilters.from || "" }} to {{ finesFilters.to || "" }} of
            {{ finesFilters.total || "" }} orders
          </p>
          <ul class="pagination pagination-flat align-self-center px-0">
            <li class="page-item" v-if="finesFilters.prev_page_url">
              <a class="page-link" @click="navigate(finesFilters.prev_page_url)"
                >← &nbsp; Prev</a
              >
            </li>
            <li class="page-item active">
              <a class="page-link"
                >On Page {{ finesFilters.current_page || "" }}</a
              >
            </li>
            <li class="page-item" v-if="finesFilters.next_page_url">
              <a class="page-link" @click="navigate(finesFilters.next_page_url)"
                >Next &nbsp; →</a
              >
            </li>
          </ul>
        </div>
        <!-- ./pagination -->
      </div>

      <!-- clients modal -->
      <v-dialog
        v-model="$store.state.dialog"
        class="text-left text-start"
        transition="dialog-top-transition"
        max-width="600"
      >
        <v-card :loading="finesForm.busy">
          <v-card-title class="text-h5">
            {{ edit ? `Delete Fine` : "New Fine" }}
          </v-card-title>
          <v-card-text>
            <!--  form -->
            <div v-if="edit" class="text-left">
              <p>
                Are you sure you want to delete '{{
                  finesForm.description || ""
                }}
              </p>

              <v-card-actions>
                <v-btn color="error darken-1" text @click="deleteFine">
                  Yes, Delete
                </v-btn>
                <v-btn color="" text @click="$store.state.dialog = false">
                  Cancel
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </div>
            <form
              v-else
              class="flex-fill text-left"
              ref="finesForm"
              @submit.prevent="createFine"
            >
              <div class>
                <div class="mb-3">
                  <span class="d-block text-muted"
                    >All fields are required</span
                  >
                </div>

                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      label="Description"
                      name="description"
                      v-model="finesForm.description"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="finesForm"
                            class="v-messages theme--light error--text"
                            field="description"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      label="Order ID"
                      name="order_id"
                      v-model="finesForm.order_id"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="finesForm"
                            class="v-messages theme--light error--text"
                            field="order_id"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                    <small class="primary--text"
                      >If the bonus is order based, enter an order ID. Otherwise
                      leave it as (-1)</small
                    >
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-autocomplete
                      style="text-align: left !important"
                      class="text-start mb-4"
                      :items="writers"
                      item-value="id"
                      item-text="first_name"
                      v-model="finesForm.writer_id"
                      label="Select writer"
                      dense
                      outlined
                      hide-details
                      spellcheck="false"
                      :filter="filterObject"
                    >
                      <template slot="selection" slot-scope="data">
                        {{ data.item.names }} - #{{ data.item.id }}
                      </template>
                      <template slot="item" slot-scope="data">
                        {{ data.item.names }} - #{{ data.item.id }}
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6" class="mb-2">
                    <v-text-field
                      dense
                      outlined
                      label="Amount"
                      name="amount"
                      type="number"
                      v-model="finesForm.amount"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="finesForm"
                            class="v-messages theme--light error--text"
                            field="amount"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>
                </v-row>

                <v-btn-toggle>
                  <v-btn
                    :disabled="finesForm.busy"
                    type="submit"
                    color="teal"
                    class="white--text"
                    >{{ edit ? "Update" : "Save Fine" }}</v-btn
                  >
                  <v-btn
                    type="button"
                    @click="$store.state.dialog = !$store.state.dialog"
                    >Close</v-btn
                  >
                </v-btn-toggle>
              </div>
            </form>

            <!-- / form -->
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- clients modal -->
    </section>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import http from "../../modules/http.index";
export default {
  name: "fines",
  computed: {
    ...mapGetters("auth", ["userType", "userTypes"]),
    ...mapState("payment", [
      "finesForm",
      "loading",
      "fines",
      "finesHeaders",
      "finesTotal",
      "finesFilters",
    ]),
    ...mapState("writers", ["writers"]),
  },
  data() {
    return {
      countries: [],
      edit: false,
      activeItem: {},
    };
  },

  methods: {
    ...mapActions("payment", ["_getFines"]),
    ...mapActions("writers", ["getAllWriters"]),
    filterObject(item, queryText, itemText) {
      return (
        item.names.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1 ||
        item.id.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
    createFine() {
      console.log("finesForm", this.finesForm);
      this.edit
        ? this.finesForm
            .put(`${this.$baseUrl}/Payments/Fines`)
            .then(() => {
              this.$store.state.dialog = false;
              this.$notify({
                title: "Success",
                text: `fine updated successifully.`,
                style: "success",
              });
              this._getFines();
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
              this.$notify({
                title: "Error",
                text: err.response.data
                  ? err.response.data.Message
                  : `Error while updating fine`,
                style: "danger",
              });
            })
        : this.finesForm
            .post(`${this.$baseUrl}/Payments/Fines`)
            .then((res) => {
              this.$store.state.dialog = false;
              this.$notify({
                title: "Success",
                text: `Fine created successifully.`,
                style: "success",
              });
              this._getFines();
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
              this.$notify({
                title: "Error",
                text: err.response.data
                  ? err.response.data.Message
                  : `Error while creating fine`,
                style: "danger",
              });
            });
    },

    deleteFine() {
      http
        .delete(`/Payments/Entry/${this.activeItem.id}`)
        .then((res) => {
          this.$store.state.dialog = false;
          this.$notify({
            title: "Success",
            text: `Fine deleted successifully.`,
            style: "success",
          });
          this._getFines();
        })
        .catch((err) => {
          this.$notify({
            title: "Error",
            text: err.response.data
              ? err.response.data.Message
              : `Error while deleting fine`,
            style: "danger",
          });
        });
    },

    editMode(item) {
      console.log(item);
      this.activeItem = item;
      Object.assign(this.finesForm, { ...item });
    },
  },
  mounted() {
    this._getFines();
    this.getAllWriters();
  },
};
</script>

